export default {
  CityOverseasgx : [{
      "en": "China",
      "cn": "中国大陆",
      "code": "86"
    },
    {
      "en": "Hongkong China",
      "cn": "中国香港",
      "code": "852"
    },
    {
      "en": "Macao China",
      "cn": "中国澳门",
      "code": "853"
    },
    {
      "en": "Taiwan China",
      "cn": "中国台湾",
      "code": "886"
    },
    {
      "en": "Angola",
      "cn": "安哥拉",
      "code": "244"
    },
    {
      "en": "Afghanistan",
      "cn": "阿富汗",
      "code": "93"
    },
    {
      "en": "Alaska(U.S.A)",
      "cn": "阿拉斯加",
      "code": "1907"
    },
    {
      "en": "Albania",
      "cn": "阿尔巴尼亚",
      "code": "335"
    },
    {
      "en": "Algeria",
      "cn": "阿尔及利亚",
      "code": "213"
    },
    {
      "en": "Andorra",
      "cn": "安道尔共和国",
      "code": "376"
    },
    {
      "en": "Anguilla",
      "cn": "安圭拉岛",
      "code": "1254"
    },
    {
      "en": "Antigua and Barbuda",
      "cn": "安提瓜和巴布达",
      "code": "1268"
    },
    {
      "en": "Argentina",
      "cn": "阿根廷",
      "code": "54"
    },
    {
      "en": "Armenia",
      "cn": "亚美尼亚",
      "code": "374"
    },
    {
      "en": "Aruba",
      "cn": "阿鲁巴岛",
      "code": "297"
    },
    {
      "en": "Ascension",
      "cn": "阿森松",
      "code": "247"
    },
    {
      "en": "Australia",
      "cn": "澳大利亚",
      "code": "61"
    },
    {
      "en": "Austria",
      "cn": "奥地利",
      "code": "43"
    },
    {
      "en": "Azerbaijan",
      "cn": "阿塞拜疆",
      "code": "994"
    },
    {
      "en": "Bahamas",
      "cn": "巴哈马",
      "code": "1242"
    },
    {
      "en": "Bahrain",
      "cn": "巴林",
      "code": "973"
    },
    {
      "en": "Bangladesh",
      "cn": "孟加拉国",
      "code": "880"
    },
    {
      "en": "Barbados",
      "cn": "巴巴多斯",
      "code": "1246"
    },
    {
      "en": "Belarus",
      "cn": "白俄罗斯",
      "code": "375"
    },
    {
      "en": "Belgium",
      "cn": "比利时",
      "code": "32"
    },
    {
      "en": "Belize",
      "cn": "伯利兹",
      "code": "501"
    },
    {
      "en": "Benin",
      "cn": "贝宁",
      "code": "229"
    },
    {
      "en": "Bermuda Is",
      "cn": "百慕大群岛",
      "code": "1441"
    },
    {
      "en": "Bhutan",
      "cn": "不丹",
      "code": "975"
    },
    {
      "en": "Bolivia",
      "cn": "玻利维亚",
      "code": "591"
    },
    {
      "en": "Bosnia And Herzegovina",
      "cn": "波斯尼亚和黑塞哥维那",
      "code": "387"
    },
    {
      "en": "Botswana",
      "cn": "博茨瓦纳",
      "code": "267"
    },
    {
      "en": "Brazil",
      "cn": "巴西",
      "code": "55"
    },
    {
      "en": "Brunei",
      "cn": "文莱",
      "code": "673"
    },
    {
      "en": "Bulgaria",
      "cn": "保加利亚",
      "code": "359"
    },
    {
      "en": "Burkina Faso",
      "cn": "布基纳法索",
      "code": "226"
    },
    {
      "en": "Burma",
      "cn": "缅甸",
      "code": "95"
    },
    {
      "en": "Burundi",
      "cn": "布隆迪",
      "code": "257"
    },
    {
      "en": "Cameroon",
      "cn": "喀麦隆",
      "code": "237"
    },
    {
      "en": "Canada",
      "cn": "加拿大",
      "code": "1"
    },
    {
      "en": "Cape Verde",
      "cn": "佛得角",
      "code": "238"
    },
    {
      "en": "Cayman Is",
      "cn": "开曼群岛",
      "code": "1345"
    },
    {
      "en": "Central African Republic",
      "cn": "中非共和国",
      "code": "236"
    },
    {
      "en": "Chad",
      "cn": "乍得",
      "code": "235"
    },
    {
      "en": "Chile",
      "cn": "智利",
      "code": "56"
    },

    {
      "en": "Colombia",
      "cn": "哥伦比亚",
      "code": "57"
    },
    {
      "en": "Congo",
      "cn": "刚果",
      "code": "242"
    },
    {
      "en": "Cook Is",
      "cn": "库克群岛",
      "code": "682"
    },
    {
      "en": "Costa Rica",
      "cn": "哥斯达黎加",
      "code": "506"
    },
    {
      "en": "Cuba",
      "cn": "古巴",
      "code": "53"
    },
    {
      "en": "Cyprus",
      "cn": "塞浦路斯",
      "code": "357"
    },
    {
      "en": "Czech Republic",
      "cn": "捷克",
      "code": "420"
    },
    {
      "en": "Republic of Croatia",
      "cn": "克罗地亚共和国",
      "code": "385"
    },
    {
      "en": "Denmark",
      "cn": "丹麦",
      "code": "45"
    },
    {
      "en": "Djibouti",
      "cn": "吉布提",
      "code": "253"
    },
    {
      "en": "Dominica Rep",
      "cn": "多米尼加共和国",
      "code": "1890"
    },
    {
      "en": "Ecuador",
      "cn": "厄瓜多尔",
      "code": "593"
    },
    {
      "en": "Egypt",
      "cn": "埃及",
      "code": "20"
    },
    {
      "en": "EI Salvador",
      "cn": "萨尔瓦多",
      "code": "503"
    },
    {
      "en": "Estonia",
      "cn": "爱沙尼亚",
      "code": "372"
    },
    {
      "en": "Ethiopia",
      "cn": "埃塞俄比亚",
      "code": "251"
    },
    {
      "en": "Fiji",
      "cn": "斐济",
      "code": "679"
    },
    {
      "en": "Finland",
      "cn": "芬兰",
      "code": "358"
    },
    {
      "en": "France",
      "cn": "法国",
      "code": "33"
    },
    {
      "en": "French Guiana",
      "cn": "法属圭亚那",
      "code": "594"
    },
    {
      "en": "French Polynesia",
      "cn": "法属玻利尼西亚",
      "code": "689"
    },
    {
      "en": "Gabon",
      "cn": "加蓬",
      "code": "241"
    },
    {
      "en": "Gambia",
      "cn": "冈比亚",
      "code": "220"
    },
    {
      "en": "Georgia",
      "cn": "格鲁吉亚",
      "code": "995"
    },
    {
      "en": "Germany",
      "cn": "德国",
      "code": "49"
    },
    {
      "en": "Ghana",
      "cn": "加纳",
      "code": "233"
    },
    {
      "en": "Gibraltar",
      "cn": "直布罗陀",
      "code": "350"
    },
    {
      "en": "Greece",
      "cn": "希腊",
      "code": "30"
    },
    {
      "en": "Grenada",
      "cn": "格林纳达",
      "code": "1809"
    },
    {
      "en": "Guam",
      "cn": "关岛",
      "code": "1671"
    },
    {
      "en": "Guatemala",
      "cn": "危地马拉",
      "code": "502"
    },
    {
      "en": "Guinea",
      "cn": "几内亚",
      "code": "224"
    },
    {
      "en": "Guyana",
      "cn": "圭亚那",
      "code": "592"
    },
    {
      "en": "Haiti",
      "cn": "海地",
      "code": "509"
    },
    {
      "en": "Honduras",
      "cn": "洪都拉斯",
      "code": "504"
    },

    {
      "en": "Hungary",
      "cn": "匈牙利",
      "code": "36"
    },
    {
      "en": "Iceland",
      "cn": "冰岛",
      "code": "354"
    },
    {
      "en": "India",
      "cn": "印度",
      "code": "91"
    },
    {
      "en": "Indonesia",
      "cn": "印度尼西亚",
      "code": "62"
    },
    {
      "en": "Iran",
      "cn": "伊朗",
      "code": "98"
    },
    {
      "en": "Iraq",
      "cn": "伊拉克",
      "code": "964"
    },
    {
      "en": "Ireland",
      "cn": "爱尔兰",
      "code": "353"
    },
    {
      "en": "Israel",
      "cn": "以色列",
      "code": "972"
    },
    {
      "en": "Italy",
      "cn": "意大利",
      "code": "39"
    },
    {
      "en": "Ivory Coast",
      "cn": "科特迪瓦",
      "code": "225"
    },
    {
      "en": "Jamaica",
      "cn": "牙买加",
      "code": "1876"
    },
    {
      "en": "Japan",
      "cn": "日本",
      "code": "81"
    },
    {
      "en": "Jordan",
      "cn": "约旦",
      "code": "962"
    },
    {
      "en": "Kampuchea (Cambodia )",
      "cn": "柬埔寨",
      "code": "855"
    },
    {
      "en": "Kazakstan",
      "cn": "哈萨克斯坦",
      "code": "327"
    },
    {
      "en": "Kenya",
      "cn": "肯尼亚",
      "code": "254"
    },
    {
      "en": "Korea",
      "cn": "韩国",
      "code": "82"
    },
    {
      "en": "Kuwait",
      "cn": "科威特",
      "code": "965"
    },
    {
      "en": "Kyrgyzstan",
      "cn": "吉尔吉斯坦",
      "code": "331"
    },
    {
      "en": "Laos",
      "cn": "老挝",
      "code": "856"
    },
    {
      "en": "Latvia",
      "cn": "拉脱维亚",
      "code": "371"
    },
    {
      "en": "Lebanon",
      "cn": "黎巴嫩",
      "code": "961"
    },
    {
      "en": "Lesotho",
      "cn": "莱索托",
      "code": "266"
    },
    {
      "en": "Liberia",
      "cn": "利比里亚",
      "code": "231"
    },
    {
      "en": "Libya",
      "cn": "利比亚",
      "code": "218"
    },
    {
      "en": "Liechtenstein",
      "cn": "列支敦士登",
      "code": "423"
    },
    {
      "en": "Lithuania",
      "cn": "立陶宛",
      "code": "370"
    },
    {
      "en": "Luxembourg",
      "cn": "卢森堡",
      "code": "352"
    },

    {
      "en": "Madagascar",
      "cn": "马达加斯加",
      "code": "261"
    },
    {
      "en": "Malawi",
      "cn": "马拉维",
      "code": "265"
    },
    {
      "en": "Malaysia",
      "cn": "马来西亚",
      "code": "60"
    },
    {
      "en": "Maldives",
      "cn": "马尔代夫",
      "code": "960"
    },
    {
      "en": "Mali",
      "cn": "马里",
      "code": "223"
    },
    {
      "en": "Malta",
      "cn": "马耳他",
      "code": "356"
    },
    {
      "en": "Mariana Is",
      "cn": "马里亚那群岛",
      "code": "1670"
    },
    {
      "en": "Martinique",
      "cn": "马提尼克",
      "code": "596"
    },
    {
      "en": "Mauritius",
      "cn": "毛里求斯",
      "code": "230"
    },
    {
      "en": "Mexico",
      "cn": "墨西哥",
      "code": "52"
    },
    {
      "en": "Moldova",
      "cn": "摩尔多瓦",
      "code": "373"
    },
    {
      "en": "Monaco",
      "cn": "摩纳哥",
      "code": "377"
    },
    {
      "en": "Mongolia",
      "cn": "蒙古",
      "code": "976"
    },
    {
      "en": "Montserrat Is",
      "cn": "蒙特塞拉特岛",
      "code": "1664"
    },
    {
      "en": "Morocco",
      "cn": "摩洛哥",
      "code": "212"
    },
    {
      "en": "Mozambique",
      "cn": "莫桑比克",
      "code": "258"
    },
    {
      "en": "Namibia",
      "cn": "纳米比亚",
      "code": "264"
    },
    {
      "en": "Nauru",
      "cn": "瑙鲁",
      "code": "674"
    },
    {
      "en": "Nepal",
      "cn": "尼泊尔",
      "code": "977"
    },
    {
      "en": "Netheriands Antilles",
      "cn": "荷属安的列斯",
      "code": "599"
    },
    {
      "en": "Netherlands",
      "cn": "荷兰",
      "code": "31"
    },
    {
      "en": "New Zealand",
      "cn": "新西兰",
      "code": "64"
    },
    {
      "en": "Nicaragua",
      "cn": "尼加拉瓜",
      "code": "505"
    },
    {
      "en": "Niger",
      "cn": "尼日尔",
      "code": "227"
    },
    {
      "en": "Nigeria",
      "cn": "尼日利亚",
      "code": "234"
    },
    {
      "en": "North Korea",
      "cn": "朝鲜",
      "code": "850"
    },
    {
      "en": "Norway",
      "cn": "挪威",
      "code": "47"
    },
    {
      "en": "Oman",
      "cn": "阿曼",
      "code": "968"
    },
    {
      "en": "Pakistan",
      "cn": "巴基斯坦",
      "code": "92"
    },
    {
      "en": "Panama",
      "cn": "巴拿马",
      "code": "507"
    },
    {
      "en": "Papua New Cuinea",
      "cn": "巴布亚新几内亚",
      "code": "675"
    },
    {
      "en": "Paraguay",
      "cn": "巴拉圭",
      "code": "595"
    },
    {
      "en": "Peru",
      "cn": "秘鲁",
      "code": "51"
    },
    {
      "en": "Philippines",
      "cn": "菲律宾",
      "code": "63"
    },
    {
      "en": "Poland",
      "cn": "波兰",
      "code": "48"
    },
    {
      "en": "Portugal",
      "cn": "葡萄牙",
      "code": "351"
    },
    {
      "en": "Puerto Rico",
      "cn": "波多黎各",
      "code": "1787"
    },
    {
      "en": "Qatar",
      "cn": "卡塔尔",
      "code": "974"
    },
    {
      "en": "Reunion",
      "cn": "留尼旺",
      "code": "262"
    },
    {
      "en": "Romania",
      "cn": "罗马尼亚",
      "code": "40"
    },
    {
      "en": "Russia",
      "cn": "俄罗斯",
      "code": "7"
    },
    {
      "en": "Saint Lueia",
      "cn": "圣卢西亚",
      "code": "1758"
    },
    {
      "en": "Saint Vincent",
      "cn": "圣文森特岛",
      "code": "1784"
    },
    {
      "en": "Samoa Eastern",
      "cn": "东萨摩亚(美)",
      "code": "684"
    },
    {
      "en": "Samoa Western",
      "cn": "西萨摩亚",
      "code": "685"
    },
    {
      "en": "San Marino",
      "cn": "圣马力诺",
      "code": "378"
    },
    {
      "en": "Sao Tome and Principe",
      "cn": "圣多美和普林西比",
      "code": "239"
    },
    {
      "en": "Saudi Arabia",
      "cn": "沙特阿拉伯",
      "code": "966"
    },
    {
      "en": "Senegal",
      "cn": "塞内加尔",
      "code": "221"
    },
    {
      "en": "Seychelles",
      "cn": "塞舌尔",
      "code": "248"
    },
    {
      "en": "Sierra Leone",
      "cn": "塞拉利昂",
      "code": "232"
    },
    {
      "en": "Singapore",
      "cn": "新加坡",
      "code": "65"
    },
    {
      "en": "Slovakia",
      "cn": "斯洛伐克",
      "code": "421"
    },
    {
      "en": "Slovenia",
      "cn": "斯洛文尼亚",
      "code": "386"
    },
    {
      "en": "Solomon Is",
      "cn": "所罗门群岛",
      "code": "677"
    },
    {
      "en": "Somali",
      "cn": "索马里",
      "code": "252"
    },
    {
      "en": "South Africa",
      "cn": "南非",
      "code": "27"
    },
    {
      "en": "Spain",
      "cn": "西班牙",
      "code": "34"
    },
    {
      "en": "SriLanka",
      "cn": "斯里兰卡",
      "code": "94"
    },
    {
      "en": "St.Lucia",
      "cn": "圣卢西亚",
      "code": "1758"
    },
    {
      "en": "St.Vincent",
      "cn": "圣文森特",
      "code": "1784"
    },
    {
      "en": "Sudan",
      "cn": "苏丹",
      "code": "249"
    },
    {
      "en": "Suriname",
      "cn": "苏里南",
      "code": "597"
    },
    {
      "en": "Swaziland",
      "cn": "斯威士兰",
      "code": "268"
    },
    {
      "en": "Sweden",
      "cn": "瑞典",
      "code": "46"
    },
    {
      "en": "Switzerland",
      "cn": "瑞士",
      "code": "41"
    },
    {
      "en": "Syria",
      "cn": "叙利亚",
      "code": "963"
    },

    {
      "en": "Tajikstan",
      "cn": "塔吉克斯坦",
      "code": "992"
    },
    {
      "en": "Tanzania",
      "cn": "坦桑尼亚",
      "code": "255"
    },
    {
      "en": "Thailand",
      "cn": "泰国",
      "code": "66"
    },
    {
      "en": "Togo",
      "cn": "多哥",
      "code": "228"
    },
    {
      "en": "Tonga",
      "cn": "汤加",
      "code": "676"
    },
    {
      "en": "Trinidad and Tobago",
      "cn": "特立尼达和多巴哥",
      "code": "1809"
    },
    {
      "en": "Tunisia",
      "cn": "突尼斯",
      "code": "216"
    },
    {
      "en": "Turkey",
      "cn": "土耳其",
      "code": "90"
    },
    {
      "en": "Turkmenistan",
      "cn": "土库曼斯坦",
      "code": "993"
    },
    {
      "en": "Uganda",
      "cn": "乌干达",
      "code": "256"
    },
    {
      "en": "Ukraine",
      "cn": "乌克兰",
      "code": "380"
    },
    {
      "en": "United Arab Emirates",
      "cn": "阿拉伯联合酋长国",
      "code": "971"
    },
    {
      "en": "United Kiongdom",
      "cn": "英国",
      "code": "44"
    },
    {
      "en": "United States of America",
      "cn": "美国",
      "code": "1"
    },
    {
      "en": "Uruguay",
      "cn": "乌拉圭",
      "code": "598"
    },
    {
      "en": "Uzbekistan",
      "cn": "乌兹别克斯坦",
      "code": "233"
    },
    {
      "en": "Venezuela",
      "cn": "委内瑞拉",
      "code": "58"
    },
    {
      "en": "Vietnam",
      "cn": "越南",
      "code": "84"
    },
    {
      "en": "Yemen",
      "cn": "也门",
      "code": "967"
    },
    {
      "en": "Yugoslavia",
      "cn": "南斯拉夫",
      "code": "381"
    },
    {
      "en": "Zimbabwe",
      "cn": "津巴布韦",
      "code": "263"
    },
    {
      "en": "Zaire",
      "cn": "扎伊尔",
      "code": "243"
    },
    {
      "en": "Zambia",
      "cn": "赞比亚",
      "code": "260"
    }
  ]
}